import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([[_ctx.btnStatus, _ctx.btnSize, _ctx.btnOptions], "btn"]),
    disabled: _ctx.isDisabled
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}