export type StorageType = "localStorage" | "sessionStorage";

class AppStorage {
  private localStorageAvailable = false;
  private sessionStorageAvailable = false;

  constructor() {
    this.localStorageAvailable = this.isStorageAvailable("localStorage");
    this.sessionStorageAvailable = this.isStorageAvailable("sessionStorage");
  }

  private isStorageAvailable(type: StorageType) {
    try {
      const storage = type === "sessionStorage" ? sessionStorage : localStorage;
      const test = "test";

      storage.setItem(test, test);
      storage.removeItem(test);
      return true;
    } catch {
      return false;
    }
  }

  get isAvailable() {
    return this.localStorageAvailable;
  }

  get isSessionStorageAvailable() {
    return this.sessionStorageAvailable;
  }

  public getItem(key: string): null | string {
    if (this.localStorageAvailable) {
      return localStorage.getItem(key) || null;
    }

    return null;
  }

  public setItem(key: string, value: string): void {
    if (this.localStorageAvailable) {
      localStorage.setItem(key, value);
    }
  }

  public removeItem(key: string): void {
    if (this.localStorageAvailable) {
      localStorage.removeItem(key);
    }
  }

  public getSessionItem(key: string): null | string {
    if (this.sessionStorageAvailable) {
      return sessionStorage.getItem(key) || null;
    }

    return null;
  }

  public setSessionItem(key: string, value: string): void {
    if (this.sessionStorageAvailable) {
      sessionStorage.setItem(key, value);
    }
  }

  public removeSessionItem(key: string): void {
    if (this.sessionStorageAvailable) {
      sessionStorage.removeItem(key);
    }
  }
}

export default new AppStorage();
