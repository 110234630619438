<template>
  <svg viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_946_1344)">
      <circle cx="31" cy="31" r="29" stroke="white" stroke-width="4" />
    </g>
    <path
      d="M60 31C60 14.9837 47.0163 2 31 2"
      stroke="#88A631"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <filter
        id="filter0_i_946_1344"
        x="0"
        y="0"
        width="62"
        height="66"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.983614 0 0 0 0 0.98363 0 0 0 0 0.983661 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_946_1344"
        />
      </filter>
    </defs>
  </svg>
</template>

<style lang="scss" scoped>
svg path {
  stroke: theme("colors.secondary.1");
}
</style>
