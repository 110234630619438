import { Theme } from "@/composables/useThemes";
import { Actor, Background, PropellerData } from "@/types/api.types";
import { ApiResponse, post } from "./api";

const API_URL = `${process.env.VUE_APP_PROPELLER_URL}`;

export type CreateVideoArgs = {
  file: Blob;
  collection: Actor["collection"];
  actor: Actor["name"];
  location: Background["name"];
  voice: string;
  skin: Theme;
};

/**
 * @todo: add types
 */
export async function createVideo(
  params: CreateVideoArgs
): Promise<ApiResponse<PropellerData>> {
  const url = `${API_URL}/share`;
  const formData = new FormData();

  formData.append("file", params.file);
  formData.append("meta", JSON.stringify({ ...params, file: undefined }));

  return await post<PropellerData>(url, formData);
}
