
import { defineComponent, PropType } from "vue";

type AlertType = "info" | "danger";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    severity: {
      type: String as PropType<AlertType>,
      default: "info",
    },
  },
});
