export const scrollInto = (el: Element | undefined): void => {
  el?.scrollIntoView({
    behavior: "smooth",
    inline: "center",
    block: "nearest",
  });
};

export const getDynamicImg = (path: string, ext?: string): string => {
  try {
    if (ext) {
      return require(`/src/assets/images/${path}.${ext}`);
    }

    return require(`/src/assets/images/${path}`);
  } catch {
    return "";
  }
};

export const secondsToTime = (value: number): string => {
  const time: number = Math.round(value) ?? 0;

  const hours: string = addLeadingZero(Math.floor(time / 3600));
  const minutes: string = addLeadingZero(
    Math.floor((time - Number(hours) * 3600) / 60)
  );
  const seconds: string = addLeadingZero(
    Math.floor(time - Number(hours) * 3600) - Number(minutes) * 60
  );

  if (Number(hours) > 0) {
    return `${hours}:${minutes}:${seconds}`;
  } else {
    return `${minutes}:${seconds}`;
  }
};

export const addLeadingZero = (value: number): string => {
  return value.toString().padStart(2, "0");
};

export const cloneDeep = (value: any): any => {
  return JSON.parse(JSON.stringify(value));
};

export function parseBooleanString(value: string): boolean {
  switch (value) {
    case "false":
    case "null":
    case "undefined":
    case "":
    case "0":
      return false;
    default:
      return Boolean(value);
  }
}

export const getSubdomain = () => {
  const hostname = window.location.hostname.split(".");

  return hostname[1] ? hostname[0] : null;
};

export const generateRequestId = () => {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
};

export const capitalizeFirstLetter = (value: string) => {
  return value[0].toUpperCase() + value.slice(1);
};

export const toPascalCase = (value: string, delimiter = "_"): string => {
  return value
    .split(delimiter)
    .map((v: string) => capitalizeFirstLetter(v))
    .join("")
    .replace(delimiter, "");
};

export const dashToPascalCase = (value: string): string => {
  return toPascalCase(value, "-");
};

export const kebabToPascalCase = (value: string): string => {
  return toPascalCase(value, "_");
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
export const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

export const preloadImage = (url: string) => {
  const img = new Image();

  if (url.startsWith("http")) {
    img.src = url;
  } else {
    img.src = getDynamicImg(url);
  }
};

export const sumStringCharCodes = (value: string): number => {
  return value.split("").reduce((prev, curr) => prev + curr.charCodeAt(0), 0);
};
