
import { defineComponent, computed } from "vue";
import AppFieldLimit from "@/components/utils/AppFieldLimit.vue";

export default defineComponent({
  components: { AppFieldLimit },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    borderRadius: {
      type: String,
      default: "xl",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    autocomplete: {
      type: String,
      default: "on",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const sizesStyles = computed(() => {
      return {
        "input-md": props.size === "md",
        "input-lg": props.size === "lg",
      };
    });

    const borderRadiusStyles = computed(() => `rounded-${props.borderRadius}`);

    const update = (value: string) => {
      emit("update:modelValue", value);
    };

    return { sizesStyles, borderRadiusStyles, update };
  },
});
