import gsap from "gsap";

export default {
  // @todo: add binding type
  mounted: (element: HTMLElement, binding: any) => {
    const options = binding.value;

    switch (binding.arg) {
      case "from":
        gsap.from(element, options).delay(options?.delay ?? 0);
        break;
      case "to":
        gsap.to(element, options).delay(options?.delay ?? 0);
        break;
      case "fromTo":
        gsap
          .fromTo(element, options.from, options.to)
          .delay(options?.delay ?? 0);
        break;
    }
  },
};
