import { sendTTS } from "@/api/postcard.api";
import { TTSData } from "@/types/api.types";
import { Audio } from "@/types/audio.types";
import { CreateState, Status } from "@/types/status.types";
import { MUTATIONS } from "@/types/store.types";
import { Commit } from "vuex";

const getters = {
  isLoading(state: { status: Status<CreateState> }) {
    return state.status.type === CreateState.Loading;
  },
  isError(state: { status: Status<CreateState> }) {
    return state.status.type === CreateState.Error;
  },
};

const mutations = {
  [MUTATIONS.SET_STATUS](
    state: { status: Status<CreateState> },
    payload: Status<CreateState>
  ): void {
    state.status = payload;
  },
  [MUTATIONS.SET_AUDIO](state: { outputAudio: Audio }, payload: string): void {
    if (state.outputAudio) {
      URL.revokeObjectURL(state?.outputAudio?.url);
    }

    const blob = new Blob([payload], { type: "audio/wav" });

    state.outputAudio = {
      blob,
      url: URL.createObjectURL(blob),
      type: "audio/wav",
    } as Audio;
  },
};

const actions = {
  async fetchAudio(
    { commit }: { commit: Commit },
    payload: TTSData
  ): Promise<void> {
    commit(MUTATIONS.SET_STATUS, {
      type: CreateState.Loading,
    });

    const [error, data] = await sendTTS(payload);

    if (error === null && data) {
      commit(MUTATIONS.SET_STATUS, {
        type: CreateState.Success,
      });
      commit(MUTATIONS.SET_AUDIO, data);
    } else {
      commit(MUTATIONS.SET_STATUS, {
        type: CreateState.Error,
        msg: error?.message,
      });
    }
  },
};

export default {
  getters,
  mutations,
  actions,
};
