import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c5cacae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = ["type", "placeholder", "disabled", "autocomplete", "modelValue", "value", "autofocus", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppFieldLimit = _resolveComponent("AppFieldLimit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: _ctx.type,
      placeholder: _ctx.placeholder,
      disabled: _ctx.disabled,
      class: _normalizeClass([[
        _ctx.sizesStyles,
        _ctx.borderRadiusStyles,
        { 'textarea--limit': _ctx.maxLength != null, error: _ctx.isError },
      ], "input"]),
      autocomplete: _ctx.autocomplete,
      modelValue: _ctx.modelValue,
      value: _ctx.modelValue,
      autofocus: _ctx.autofocus,
      maxlength: _ctx.maxLength,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.update($event.target.value)))
    }, null, 42, _hoisted_2),
    (_ctx.maxLength != null)
      ? (_openBlock(), _createBlock(_component_AppFieldLimit, {
          key: 0,
          class: "limit"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.maxLength - _ctx.modelValue.length), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}