
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import AppButton from "@/components/utils/AppButton.vue";
import AppIcon from "@/components/utils/AppIcon.vue";
import LogoutIcon from "@/components/icons/IconLogout.vue";
import { useRouter } from "vue-router";
import ButtonBack from "@/components/ButtonBack.vue";

export default defineComponent({
  components: {
    AppButton,
    AppIcon,
    LogoutIcon,
    ButtonBack,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isAuthenticated = computed(
      () => store.getters["user/isAuthenticated"]
    );

    const handleLogout = () => {
      router.push({ name: "home" });
    };

    return { isAuthenticated, handleLogout };
  },
});
