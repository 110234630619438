import { Actor, Voice } from "@/types/api.types";
import { MUTATIONS } from "@/types/store.types";
import { reactive } from "vue";
import store from "..";

const initialState = {
  isConfirmed: false,
  // @todo: update tmp data
  activeActor: null as Actor | null,
  activeVoice: null as Voice | null,
};

const state = reactive(initialState);

const getters = {
  selectedActor: (state: ChooseState) => {
    return state.activeActor;
  },
  selectedVoice: (state: ChooseState) => {
    return state.activeVoice;
  },
  getVoiceFilepath: () => (filename: string) => {
    return `voices/${store.state.app.theme}/${filename}.png`;
  },
};

const mutations = {
  [MUTATIONS.SET_ACTIVE_ACTOR](state: ChooseState, value: Actor) {
    state.activeActor = value;
  },
  [MUTATIONS.SET_ACTIVE_VOICE](state: ChooseState, value: Voice) {
    state.activeVoice = value;
  },
  [MUTATIONS.SET_CONFIRMATION](state: ChooseState, value: boolean) {
    state.isConfirmed = value;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export type ChooseState = typeof initialState;
