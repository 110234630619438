
import AppHeader from "./components/utils/AppHeader.vue";
import AppFooter from "./components/utils/AppFooter.vue";
import useAnalytics from "@/composables/useAnalytics";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(utc);

/** Default App entry point
 * @exports app:App
 */
export default {
  components: { AppHeader, AppFooter },
  setup() {
    const { trackPageLeave } = useAnalytics();

    window.addEventListener("beforeunload", () => {
      trackPageLeave(true);
    });
  },
};
