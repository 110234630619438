import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(["inline-block transform transition duration-300 ease-out", {
      ['rotate-' + $props.rotate]: !!$props.rotate,
      ['fill-' + $props.fill]: !!$props.fill,
      ['stroke-' + $props.stroke]: !!$props.stroke,
      ['icon-' + $props.size]: !!$props.size,
      disabled: $props.disabled,
    }])
  }, [
    _createElementVNode("g", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}