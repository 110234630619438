
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import AppButton from "@/components/utils/AppButton.vue";
import AppInput from "@/components/utils/AppInput.vue";
import AppAlert from "@/components/utils/AppAlert.vue";
import { FormStatus } from "@/types/status.types";
import AppIcon from "@/components/utils/AppIcon.vue";
import IconLoader from "@/components/icons/IconLoader.vue";

export default defineComponent({
  name: "Home",
  components: {
    AppButton,
    AppInput,
    AppAlert,
    AppIcon,
    IconLoader,
  },
  setup() {
    const data = reactive({
      accessKey: "",
    });

    const passwordRef = ref(null);

    const store = useStore();
    const router = useRouter();

    const loginStatus = computed(() => store.state.user.loginStatus);
    const isAuthenticated = computed(
      () => store.getters["user/isAuthenticated"]
    );

    (() => {
      store.dispatch("reset");
    })();

    const login = async () => {
      const response = await store.dispatch("user/login", data.accessKey);

      if (response.type === FormStatus.Success) {
        router.push({ name: "choose" });
      }
    };

    return {
      data,
      loginStatus,
      FormStatus,
      passwordRef,
      isAuthenticated,
      login,
    };
  },
});
