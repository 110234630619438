import { defaultTheme, Theme } from "@/composables/useThemes";
import { MUTATIONS } from "@/types/store.types";
import { GENERIC_ERROR } from "@/utils/messages";
import { reactive } from "@vue/runtime-dom";
import { register } from "extendable-media-recorder";
import { connect } from "extendable-media-recorder-wav-encoder";
import { Commit } from "vuex";

const initialState = {
  isConnected: false,
  theme: defaultTheme as Theme,
};

const state = reactive(initialState);

const getters = {
  theme: (state: AppState): string => {
    return state.theme;
  },
};

const mutations = {
  [MUTATIONS.SET_RECORDER](): void {
    state.isConnected = true;
  },
  [MUTATIONS.SET_THEME](state: AppState, payload: Theme): void {
    state.theme = payload;
  },
};

const actions = {
  async fetchConnection({ commit }: { commit: Commit }): Promise<void> {
    if (!state.isConnected) {
      /**
       * @desc: needed for wav format.
       * @see:
       * https://stackoverflow.com/a/65191456
       * https://github.com/chrisguttandin/extendable-media-recorder
       */
      try {
        await register(await connect());
        commit(MUTATIONS.SET_RECORDER);
      } catch (e: unknown) {
        // TODO: fix connection workaround for hot reloading
        console.warn(e instanceof Error ? e.message : GENERIC_ERROR);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export type AppState = typeof initialState;
