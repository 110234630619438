import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRouter,
} from "vue-router";
import Home from "../views/Home.vue";
import useAnalytics from "@/composables/useAnalytics";
import store from "@/store";
import AppStorage from "@/utils/storage";
import useRouteGuard, { RouteName } from "@/composables/useRouteGuard";
import { preloadImage } from "@/utils";
import { getActorAnimatedImg } from "@/api/postcard.api";

const routeGuard = useRouteGuard(store);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: RouteName.HOME,
    component: Home,
  },
  {
    path: "/welcome",
    name: RouteName.WELCOME,
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/Welcome.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/choose",
    name: RouteName.CHOOSE,
    component: () =>
      import(/* webpackChunkName: "choose" */ "../views/Choose.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/combine",
    name: RouteName.COMBINE,
    component: () =>
      import(/* webpackChunkName: "combine" */ "../views/Combine.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter() {
      preloadImage("thunder");

      const actor = store.state.choose.activeActor;
      if (actor) {
        preloadImage(getActorAnimatedImg(actor.collection, actor.name));
      }
    },
  },
  {
    path: "/convert",
    name: RouteName.CONVERT,
    component: () =>
      import(/* webpackChunkName: "convert" */ "../views/Convert.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/message/:id",
    name: RouteName.MESSAGE,
    component: () => import("../views/SimpleMessage.vue"),
  },
  {
    path: "/dev-analytics",
    name: RouteName.DEV_ANALYTICS,
    component: {
      setup() {
        useAnalytics().setIsDevTrackingForced(true);
        alert("Analytics is now in developer mode.");
        useRouter().push({ name: "home" });
      },
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const { trackPageLeave } = useAnalytics();

  trackPageLeave(false);

  if (to.name !== RouteName.MESSAGE) {
    const localAccessKey = AppStorage.getItem("accessKey");

    /**
     * @todo: add better user id generation support
     */
    if (!store.getters["user/accessKey"]) {
      await store.dispatch("user/fetchUniqueUserId");
    }

    if (localAccessKey && !store.getters["user/isAuthenticated"]) {
      const accessKeyConfirmation = await store.dispatch(
        "user/login",
        localAccessKey
      );
      if (!accessKeyConfirmation) {
        return "/";
      }
    }

    if (to.meta.requiresAuth && !store.getters["user/isAuthenticated"]) {
      return "/";
    }

    if (routeGuard.isRouteAllowed(from, to) === false) {
      return "/choose";
    }
  }
});

router.afterEach((to, from, failure) => {
  const { trackPageEnter } = useAnalytics();

  if (!failure && to.name) {
    trackPageEnter(String(to.name));
  }
});

export default router;
