import axios, { AxiosError, AxiosResponseHeaders, ResponseType } from "axios";
import store from "@/store";
import { generateRequestId } from "@/utils";

export type ApiResponse<T> = [AxiosError] | [null, T, AxiosResponseHeaders];

export async function get<T>(
  url: string,
  requestHeaders?: Record<string, string>,
  responseType: ResponseType = "json"
): Promise<ApiResponse<T>> {
  try {
    const { data, headers: responseHeaders } = await axios({
      method: "get",
      url,
      headers: requestHeaders,
      responseType,
    });

    return [null, data, responseHeaders];
  } catch (error) {
    return [error as AxiosError];
  }
}

export async function authorizedGet<T>(
  url: string,
  requestHeaders?: Record<string, string>,
  responseType?: ResponseType
) {
  return get<T>(
    url,
    { ...requestHeaders, ...getAuthHeaders(), ...getOptionsHeaders() },
    responseType
  );
}

export async function post<T, D = unknown>(
  url: string,
  postData?: D,
  requestHeaders?: Record<string, string>,
  responseType: ResponseType = "json"
): Promise<ApiResponse<T>> {
  try {
    const { data, headers: responseHeaders } = await axios({
      method: "post",
      url,
      data: postData,
      headers: requestHeaders,
      responseType,
    });

    return [null, data, responseHeaders];
  } catch (error) {
    return [error as AxiosError];
  }
}

export async function authorizedPost<T, D = unknown>(
  url: string,
  postData?: D,
  requestHeaders?: Record<string, string>,
  responseType?: ResponseType
) {
  return post<T>(
    url,
    postData,
    { ...requestHeaders, ...getAuthHeaders(), ...getOptionsHeaders() },
    responseType
  );
}

function getAuthHeaders(): Record<string, string> {
  const requestId = generateRequestId();

  const result: Record<string, string> = {
    "postcard-access-code": store.getters["user/accessKey"],
    "postcard-user-hash": store.getters["user/uniqueUserId"],
    requestid: String(requestId),
  };

  return result;
}

function getOptionsHeaders(): Record<string, string> {
  return {
    theme: store.getters["app/theme"],
  };
}
