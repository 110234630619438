import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Hotjar from "./plugins/hotjar";
import GoogleAnalytics from "./plugins/google-analytics";
import * as Sentry from "@sentry/vue";
import "tailwindcss/tailwind.css";
import useThemes from "./composables/useThemes";
import { getSubdomain } from "./utils";
import animate from "./directives/animate";

const app = createApp(App);
const themes = useThemes();
const subdomain = getSubdomain();

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_URL,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
});

/**
 * @desc: setup initial theme
 */
const i18n = themes.setup({
  locale: subdomain,
});

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(Hotjar, { siteId: parseInt(process.env.VUE_APP_HOTJAR_SITE_ID) })
  .use(GoogleAnalytics, {
    measurementId: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  })
  .directive("animate", animate)
  .mount("#app");

/**
 * @desc: load theme dynamically
 */
(async () => {
  if (subdomain) {
    await themes.loadTheme(i18n, subdomain);
  }
})();
