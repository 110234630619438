import {
  AccessKeyValidation,
  AccessResponse,
  Keywords,
  PhraseNLPData,
  SlotsNLPData,
  Story,
  TopicNLPData,
  Topics,
  TTSData,
  VCData,
} from "@/types/api.types";
import { ApiResponse, authorizedGet, authorizedPost, post } from "./api";
import store from "@/store";
import { sumStringCharCodes } from "@/utils";

const API_URL = process.env.VUE_APP_API_URL;

enum API_VERSION {
  V1 = "v1",
  V2 = "v2",
}

export async function login(
  accessKey: string
): Promise<ApiResponse<AccessResponse>> {
  return post<AccessResponse>(
    `${API_URL}/${API_VERSION.V1}/auth/access-key`,
    {
      accessKey: accessKey,
    },
    {
      theme: store.getters["app/theme"],
    }
  );
}

export async function getTopics(): Promise<ApiResponse<Topics>> {
  return authorizedGet<Topics>(`${API_URL}/${API_VERSION.V2}/nlp/topic`, {
    "postcard-user-hash": "testUser",
  });
}

export async function getSlots(): Promise<ApiResponse<Keywords>> {
  return authorizedGet<Keywords>(`${API_URL}/${API_VERSION.V2}/nlp/slots`, {
    "postcard-user-hash": "testUser",
  });
}

export async function sendVC(data: VCData): Promise<ApiResponse<Blob>> {
  const formData = new FormData();
  formData.append("file", data.audio.blob);

  return authorizedPost<Blob>(
    `${API_URL}/${API_VERSION.V1}/vc/${data.voice}`,
    formData,
    undefined,
    "blob"
  );
}

export async function sendTTS(data: TTSData): Promise<ApiResponse<string>> {
  return authorizedPost<string>(
    `${API_URL}/${API_VERSION.V1}/tts`,
    data,
    undefined,
    "blob"
  );
}

export async function sendPhraseNLP(
  data: PhraseNLPData
): Promise<ApiResponse<Story>> {
  return authorizedPost<Story>(
    `${API_URL}/${API_VERSION.V2}/nlp/phrase`,
    data,
    {
      "postcard-user-hash": "testUser",
    }
  );
}

export async function sendTopicNLP(
  data: TopicNLPData
): Promise<ApiResponse<Story>> {
  return authorizedPost<Story>(`${API_URL}/${API_VERSION.V2}/nlp/topic`, data);
}

export async function sendKeywordsNLP(
  data: SlotsNLPData
): Promise<ApiResponse<Story>> {
  return authorizedPost<Story>(`${API_URL}/${API_VERSION.V2}/nlp/slots`, data);
}

export async function getNlpTokens() {
  return authorizedGet<null>(`${API_URL}/${API_VERSION.V2}/nlp/status`);
}

export type CollectedInputDataType = "greetings" | "sentence" | "tts_content";

export async function collectInputData(
  type: CollectedInputDataType,
  text: string
): Promise<unknown> {
  return post<AccessKeyValidation>(`${API_URL}/${API_VERSION.V1}/collect`, {
    type,
    text,
  }).catch((e) => {
    console.log(e);
  });
}

function getActorAssetUrl(
  collection: string,
  name: string,
  asset: string
): string {
  return `${process.env.VUE_APP_ASSETS_URL}/v1/${collection}/actor/${name}/${asset}`;
}

export function getActorStaticImg(collection: string, name: string): string {
  return getActorAssetUrl(collection, name, "act_icon_web.png");
}

export function getActorAnimatedImg(collection: string, name: string): string {
  return getActorAssetUrl(collection, name, "act_icon_animated.png");
}

export function calculateActorPrice(
  key: string,
  name: string,
  collectionNamePriceFactor: number
): number {
  let actorNamePriceFactor;

  if (Number.isInteger(Number(name))) {
    actorNamePriceFactor = (Number(name) % 100) / 100;
  } else {
    actorNamePriceFactor = (sumStringCharCodes(name) % 100) / 100;
  }

  let price = (sumStringCharCodes(key) / 100) * collectionNamePriceFactor;
  price += price * actorNamePriceFactor;

  return Number(price.toFixed(2));
}
