import mixpanel, { Dict, RequestOptions } from "mixpanel-browser";
import { ref } from "@vue/reactivity";
import store from "@/store";
import AppStorage from "@/utils/storage";
import { parseBooleanString } from "@/utils";

export interface AnalyticsPageData {
  name: string;
  enterTime: Date;
}

let isInitialized = false;
const pageInitialEnterTime = new Date();
const currentPageData = ref<AnalyticsPageData | null>(null);

export enum AudioOutputGeneratedType {
  AI = "ai",
  DIY = "diy",
}

export enum AnalyticsEvent {
  LeftPage = "LeftPage",
  EnteredPage = "EnteredPage",
  ConvertingPageInteraction = "ConvertingPageInteraction",
  GoNext = "GoNext",
  GoBack = "GoBack",
  AudioInputRecorded = "AudioInputRecorded",
  AudioOutputGenerated = "AudioOutputGenerated",
  StoryGenerated = "StoryGenerated",
  StoriesPerSentence = "StoriesPerSentence",
  DoUsersEditStories = "DoUsersEditStories",
  VideoPlayed = "VideoPlayed",
  VideoEnded = "VideoEnded",
  VideoShared = "VideoShared",
  CreateNew = "CreateNew",
  CreateMyOwn = "CreateMyOwn",
  AllNLPTokensUsed = "AllNLPTokensUsed",
  InstructionLearned = "InstructionLearned",
  PlayVoiceSample = "PlayVoiceSample",
}

export default function useAnalytics() {
  const ANALYTICS_FORCE_DEV_KEY = "ANALYTICS_FORCE_DEV";
  const mixpanelToken = process.env.VUE_APP_MIXPANEL_TOKEN;

  if (!isInitialized && mixpanelToken) {
    mixpanel.init(mixpanelToken, {
      debug: process.env.NODE_ENV !== "production",
    });

    isInitialized = true;
  }

  function isDevTracking(): boolean {
    return (
      AppStorage.getItem(ANALYTICS_FORCE_DEV_KEY) !== null ||
      parseBooleanString(process.env.VUE_APP_MIXPANEL_IS_DEV) ||
      process.env.NODE_ENV !== "production"
    );
  }

  function setIsDevTrackingForced(flag: boolean): void {
    if (flag) {
      AppStorage.setItem(ANALYTICS_FORCE_DEV_KEY, "true");
    } else {
      AppStorage.removeItem(ANALYTICS_FORCE_DEV_KEY);
    }
  }

  function track<T extends Dict>(
    eventName: AnalyticsEvent,
    properties: T,
    options?: RequestOptions
  ): void {
    if (!isInitialized) {
      return;
    }

    mixpanel.track(
      eventName,
      {
        ...properties,
        current_page: currentPageData?.value?.name || undefined,
        seconds_spent_app_total: getSecondsSpent(pageInitialEnterTime),
        seconds_spent_subpage_so_far:
          currentPageData?.value &&
          getSecondsSpent(currentPageData.value.enterTime as Date),
        access_key: store.getters["user/accessKey"] || null,
        env: process.env.NODE_ENV,
        is_dev: isDevTracking(),
      },
      options
    );
  }

  function trackPageLeave(isUnloadEvent: boolean): void {
    if (currentPageData.value) {
      const options = isUnloadEvent
        ? ({ transport: "sendBeacon" } as const)
        : {};

      return track(
        AnalyticsEvent.LeftPage,
        {
          screen_name: currentPageData.value.name,
          is_unload: isUnloadEvent,
        },
        options
      );
    }
  }

  function trackPageEnter(pageName: string): void {
    currentPageData.value = {
      name: pageName,
      enterTime: new Date(),
    };

    return track(AnalyticsEvent.EnteredPage, {
      screen_name: currentPageData.value.name,
    });
  }

  function getSecondsSpent(start: Date): number {
    return Math.abs(new Date().getTime() - start.getTime()) / 1000;
  }

  return {
    track,
    trackPageEnter,
    trackPageLeave,
    setIsDevTrackingForced,
  };
}
