import { reactive } from "vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ActionContext, Commit } from "vuex";
import { calculateActorPrice, getNlpTokens, login } from "@/api/postcard.api";
import AppStorage from "@/utils/storage";
import { MUTATIONS } from "@/types/store.types";
import { AxiosError, AxiosResponseHeaders } from "axios";
import { HttpStatusCode } from "@/types/http.types";
import useAnalytics, { AnalyticsEvent } from "@/composables/useAnalytics";
import { limitMessage } from "@/utils/messages";
import { FormStatus } from "@/types/status.types";
import { Status } from "@/types/status.types";
import { State } from "..";
import { Actor, Voice } from "@/types/api.types";
import { kebabToPascalCase } from "@/utils";

type TokenLimits = {
  left: number | null;
  resetDate: Date | null;
};

const fpPromise = FingerprintJS.load({});

const initialState = {
  loginStatus: {
    type: FormStatus.Idle,
  } as Status<FormStatus>,
  uniqueUserId: "",
  accessKey: "",
  diyTokens: {
    left: null,
    resetDate: null,
  } as TokenLimits,
  nlpTokens: {
    left: null,
    resetDate: null,
  } as TokenLimits,
  actors: [] as Actor[],
  voices: [] as Voice[],
};

export const NLP_TOKENS_LIMIT = 100 as const;
export const DIY_TOKENS_LIMIT = false;
export const TYPEFORM_RECEIVER = "https://a8u601d6zse.typeform.com/to/jo7OsY9n";
export const TYPEFORM_SENDER = "https://a8u601d6zse.typeform.com/to/IHntS2LG";
export const TYPEFORM_RETURNING_USER =
  "https://a8u601d6zse.typeform.com/to/kZDEcdaD";

const state = reactive(initialState);

const getters = {
  accessKey: (state: UserState): string | null => {
    return state.accessKey || null;
  },
  uniqueUserId: (state: UserState): string => {
    return state.uniqueUserId;
  },
  isAuthenticated: (): boolean => {
    //@TODO: add auth
    return state.accessKey !== "";
  },
  diyLimitMessage: (state: UserState): string => {
    const { left: triesLeft, resetDate } = state.diyTokens;

    return limitMessage({
      triesLeft,
      resetDate,
      limit: DIY_TOKENS_LIMIT,
    });
  },
  nlpLimitMessage: (state: UserState): string => {
    const { left: triesLeft, resetDate } = state.nlpTokens;

    return limitMessage({
      triesLeft,
      resetDate,
      limit: NLP_TOKENS_LIMIT,
    });
  },
};

const mutations = {
  [MUTATIONS.RESET](state: UserState): void {
    AppStorage.removeItem("accessKey");
    state.accessKey = "";
  },
  setUniqueUserId(state: UserState, id: string): void {
    state.uniqueUserId = id;
  },
  setAccessKey(state: UserState, accessKey: string): void {
    state.accessKey = accessKey;
    AppStorage.setItem("accessKey", accessKey);
  },
  [MUTATIONS.SET_NLP_TOKENS](
    state: UserState,
    payload: { error: AxiosError; headers: AxiosResponseHeaders }
  ): void {
    const headers =
      (payload.error?.response?.status === HttpStatusCode.TOO_MANY_REQUESTS &&
        payload.error?.response?.headers) ||
      payload.headers;

    state.nlpTokens.left = parseInt(headers["postcard-ai-magic-points-left"]);
    state.nlpTokens.resetDate = new Date(
      headers["postcard-ai-magic-points-reset"]
    );

    if (state.nlpTokens.left === 0) {
      const { track } = useAnalytics();
      track(AnalyticsEvent.AllNLPTokensUsed, {});
    }
  },
  [MUTATIONS.SET_ACTORS](state: UserState, payload: Actor[]): void {
    state.actors = payload.map((actor: Actor) => {
      let collectionPriceFactor = 1;

      switch (actor.collection) {
        case "catalina_whale":
          actor.collectionDisplayName = "Catalina Whale Mixer";
          actor.coin = "SOL";
          collectionPriceFactor = 0.35;
          break;
        case "inbetweeners":
          actor.collectionDisplayName = "inBetweeners by GianPiero";
          actor.coin = "WETH";
          collectionPriceFactor = 0.03;
          break;
        case "cryptopunks":
          actor.collectionDisplayName = "CryptoPunks";
          actor.coin = "ETH";
          collectionPriceFactor = 1.83;
          break;
        case "bayc":
          actor.collectionDisplayName = "Bored Ape Yacht Club";
          actor.coin = "WETH";
          collectionPriceFactor = 3;
          break;
        case "world_of_women":
          actor.collectionDisplayName = "World of Women";
          actor.coin = "WETH";
          collectionPriceFactor = 2.5;
          break;
        case "cool_cats":
          actor.collectionDisplayName = "Cool Cats NFT";
          actor.coin = "WETH";
          collectionPriceFactor = 0.17;
          break;
        case "doodles":
          actor.collectionDisplayName = "Doodles";
          actor.coin = "WETH";
          collectionPriceFactor = 0.25;
          break;
        case "wrapped_ether_rock":
          actor.collectionDisplayName = "Wrapped Ether Rock";
          actor.coin = "WETH";
          collectionPriceFactor = 0.01;
          break;
        case "junkyard_dogs":
          actor.collectionDisplayName = "JunkYard Dogs";
          actor.coin = "ETH";
          collectionPriceFactor = 0.01;
          break;
        default:
          actor.collectionDisplayName = kebabToPascalCase(actor.collection);
          actor.coin = "ETH";
      }

      actor.price = calculateActorPrice(
        actor.key,
        actor.name,
        collectionPriceFactor
      );

      return actor;
    });
  },
  [MUTATIONS.SET_VOICES](state: UserState, payload: Voice[]): void {
    state.voices = payload.map((voice: Voice) => {
      return {
        ...voice,
        filename: voice.name.toLowerCase().replace(/[^a-zA-Z0-9]/g, ""),
        // simulate an NFT id by sum of char codes of the voice id
        nftId: voice.id
          .split("")
          .reduce((prev, curr) => prev + curr.charCodeAt(0), 0),
      };
    });
  },
};

const actions = {
  reset({ commit }: { commit: Commit }) {
    commit(MUTATIONS.RESET);
  },
  async fetchUniqueUserId({ commit }: { commit: Commit }): Promise<boolean> {
    const fp = await fpPromise;
    const { visitorId } = await fp.get();
    if (visitorId) {
      commit("setUniqueUserId", visitorId);
      return true;
    } else {
      return false;
    }
  },
  async login(
    context: UserContext,
    accessKey: string
  ): Promise<Status<FormStatus> | void> {
    const { commit, state } = context;

    if (state.loginStatus.type === FormStatus.Loading) {
      return;
    }

    state.loginStatus = {
      type: FormStatus.Loading,
    };

    const [error, accessResponse] = await login(accessKey);

    if (error === null && accessResponse) {
      commit("setAccessKey", accessKey);
      commit(MUTATIONS.SET_ACTORS, accessResponse.access.actors);
      commit(MUTATIONS.SET_VOICES, accessResponse.access.voices);

      state.loginStatus = {
        type: FormStatus.Success,
      };

      return state.loginStatus;
    } else {
      AppStorage.removeItem("accessKey");

      state.loginStatus = {
        type: FormStatus.Error,
        msg: "Invalid Password. Please reach out to the charactr team if you are having any issues with your password.",
      };

      return state.loginStatus;
    }
  },
  async fetchNlpTokens({ commit }: { commit: Commit }) {
    const [error, , headers] = await getNlpTokens();
    commit(MUTATIONS.SET_NLP_TOKENS, { error, headers });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export type UserState = typeof initialState;
export type UserContext = ActionContext<UserState, State>;
