<template>
  <svg viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.999998 20L11 10.5L1 0.999999"
      stroke="primary-2"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
