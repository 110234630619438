<template>
  <svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.621594 0.396973C0.278297 0.396973 0 0.702554 0 1.07951V22.9206C0 23.1017 0.0654892 23.2753 0.182061 23.4033C0.298632 23.5313 0.456737 23.6032 0.621594 23.6032H14.2967C14.64 23.6032 14.9183 23.2976 14.9183 22.9206V12.8288H22.0339L18.476 16.3867C18.1524 16.7104 18.1524 17.2352 18.476 17.5588C18.7997 17.8825 19.3245 17.8825 19.6481 17.5588L24.6203 12.5867L24.6215 12.5855L25.2069 12L19.6481 6.44123C19.3245 6.11757 18.7997 6.11757 18.476 6.44123C18.1524 6.7649 18.1524 7.28966 18.476 7.61332L22.0339 11.1712H14.9183V1.07951C14.9183 0.702554 14.64 0.396973 14.2967 0.396973H0.621594ZM13.6751 11.1712V1.76204H1.24319V22.2381H13.6751V12.8288H9.11656C8.65883 12.8288 8.28777 12.4578 8.28777 12C8.28777 11.5423 8.65883 11.1712 9.11656 11.1712H13.6751Z"
      fill="white"
    />
  </svg>
</template>
