
import { computed, defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    status: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "md",
    },
    border: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const btnStatus = computed(() => {
      if (props.border) {
        return `btn-${props.status}-border`;
      }
      return `btn-${props.status}`;
    });

    const btnSize = computed(() => {
      return {
        "btn-xs": props.size === "xs",
        "btn-sm": props.size === "sm",
        "btn-md": props.size === "md",
        "btn-lg": props.size === "lg",
      };
    });

    const isDisabled = computed(() => props.disabled || props.loading);

    const btnOptions = computed(() => {
      return {
        "@apply bg-system-2": isDisabled.value && !props.border,
      };
    });

    return {
      btnStatus,
      btnSize,
      btnOptions,
      isDisabled,
    };
  },
});
