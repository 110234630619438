import { createStore, Dispatch } from "vuex";
import app from "./modules/app";
import user from "./modules/user";
import choose from "./modules/choose";
import convert from "./modules/convert";

const initialState = {
  app: app.state,
  user: user.state,
  choose: choose.state,
  convert: convert.state,
};

const store = createStore({
  modules: {
    app,
    user,
    choose,
    convert,
  },
  actions: {
    reset({ dispatch }: { dispatch: Dispatch }) {
      dispatch(`user/reset`);
    },
  },
});

export default store;

export type State = typeof initialState;
export type Store = typeof store;
