import { sendVC } from "@/api/postcard.api";
import { createVideo } from "@/api/propeller.api";
import { VCData } from "@/types/api.types";
import { Audio } from "@/types/audio.types";
import { CreateState } from "@/types/status.types";
import { MUTATIONS } from "@/types/store.types";
import { Recording } from "@/types/video.types";
import { reactive } from "vue";
import { ActionContext, Commit } from "vuex";
import store, { State } from "..";
import mixins from "./mixins";

const initialState = {
  recordingsList: [] as Recording[],
  videoIdList: [] as string[],
  status: {
    type: CreateState.Idle,
  },
};

const state = reactive(initialState);

const getters = {
  isLoading: mixins.getters.isLoading,
};

const mutations = {
  [MUTATIONS.ADD_RECORDING](state: ConvertState, payload: Recording) {
    state.recordingsList.unshift(payload);
  },
  [MUTATIONS.SET_STATUS]: mixins.mutations[MUTATIONS.SET_STATUS],
};

const actions = {
  async fetchConversion(
    { commit }: { commit: Commit },
    payload: VCData
  ): Promise<Audio | void> {
    commit(MUTATIONS.SET_STATUS, {
      type: CreateState.Loading,
    });

    const [error, data] = await sendVC(payload);

    if (error === null && data) {
      const audio = {
        blob: data,
        length: data?.size || -1,
        url: URL.createObjectURL(data),
        type: "audio/wav",
      };

      commit(MUTATIONS.SET_STATUS, {
        type: CreateState.Success,
      });

      return audio;
    } else {
      commit(MUTATIONS.SET_STATUS, {
        type: CreateState.Error,
        msg: error?.message,
      });
    }
  },
  async fetchVideo(context: ConvertContext, audio: Audio) {
    context.commit(MUTATIONS.SET_STATUS, {
      type: CreateState.Loading,
    });

    /**
     * @todo: add location support
     */
    const chosenActor = store.getters["choose/selectedActor"];

    const [error, data] = await createVideo({
      file: audio.blob,
      collection: chosenActor.collection,
      actor: chosenActor.name,
      location: chosenActor.background.name,
      voice: store.getters["choose/selectedVoice"].name,
      skin: store.state.app.theme,
    });

    if (error || data === undefined) {
      const msg = "Error fetching video";
      // @TODO: Create a handler for error
      console.warn(msg);

      context.commit(MUTATIONS.SET_STATUS, {
        type: CreateState.Error,
        msg,
      });
    } else {
      context.commit(MUTATIONS.ADD_RECORDING, {
        actor: chosenActor,
        videoId: data.link,
      });
      context.commit(MUTATIONS.SET_STATUS, {
        type: CreateState.Success,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export type ConvertState = typeof initialState;
export type ConvertContext = ActionContext<ConvertState, State>;
