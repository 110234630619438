declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export interface GoogleAnalyticsOptions {
  measurementId: string;
}

export default {
  install: (app: unknown, options: GoogleAnalyticsOptions) => {
    if (!options.measurementId) {
      return;
    }

    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${options.measurementId}`;

    const headEl = document.querySelector("head");
    headEl?.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(...args: unknown[]) {
      window.dataLayer.push(args);
    }
    gtag("js", new Date());
    gtag("config", options.measurementId);
  },
};
