
import useAnalytics, { AnalyticsEvent } from "@/composables/useAnalytics";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import IconArrow from "./icons/IconArrow.vue";
import AppButton from "./utils/AppButton.vue";
import AppIcon from "./utils/AppIcon.vue";

export default defineComponent({
  components: { AppIcon, AppButton, IconArrow },
  props: {
    size: {
      type: String,
      default: "md",
    },
    iconStroke: {
      type: String,
      default: "primary-2",
    },
    iconSize: {
      type: String,
      default: "xs",
    },
  },
  setup() {
    const router = useRouter();
    const { track } = useAnalytics();

    const onBackClick = () => {
      track(AnalyticsEvent.GoBack, { hasAudio: true });
      router.go(-1);
    };

    return { onBackClick };
  },
});
