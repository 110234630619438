<template>
  <p class="limit">
    <slot />
  </p>
</template>

<style lang="scss" scoped>
.limit {
  max-height: 18px; // @desc: safari fix
  @apply h-fit px-2 text-sm text-other-2;
}
</style>
