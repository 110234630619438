import { RouteLocationNormalized } from "vue-router";
import { Store } from "@/store";

export const RouteName = {
  HOME: "home",
  WELCOME: "welcome",
  CREATE: "create",
  CREATE_BASE: "create-base",
  CREATE_VC: "create-vc",
  CREATE_MESSAGE: "create-message",
  CREATE_SENTENCE: "create-sentence",
  CREATE_TOPIC: "create-topic",
  CREATE_KEYWORDS: "create-keywords",
  GREETING: "greeting",
  SHARE: "share",
  MESSAGE: "message",
  DEV_ANALYTICS: "dev-analytics",
  CHOOSE: "choose",
  CONVERT: "convert",
  COMBINE: "combine",
};

export default function useRouteGuard(store: Store) {
  const isRouteAllowed = (
    from: RouteLocationNormalized,
    to: RouteLocationNormalized
  ): boolean => {
    switch (to.name) {
      case RouteName.COMBINE: {
        const actor = store.getters["choose/selectedActor"];
        const voice = store.getters["choose/selectedVoice"];

        return (
          from.name === RouteName.CHOOSE && actor !== null && voice !== null
        );
      }
      case RouteName.CONVERT: {
        const actor = store.getters["choose/selectedActor"];
        const voice = store.getters["choose/selectedVoice"];

        return (
          from.name === RouteName.COMBINE && actor !== null && voice !== null
        );
      }
      default:
        return true;
    }
  };

  return {
    isRouteAllowed,
  };
}
