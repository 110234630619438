import { nextTick, Ref } from "vue";
import { createI18n, I18n } from "vue-i18n";
import * as defaultContent from "@/locales/postcard.json";
import store from "@/store";

export enum Theme {
  Postcard = "postcard",
  Holiday = "holiday",
  Nft = "nft",
}

export const subdomainToTheme = {
  postcard: Theme.Postcard,
  holidaygreetings: Theme.Holiday,
  nft: Theme.Nft,
};

export const defaultTheme = Theme.Nft;

type Subdomain = keyof typeof subdomainToTheme;

export default function useThemes() {
  const setup = (options: { locale: string | null }): I18n => {
    const themeName = getThemeName(options.locale);

    const currentTheme = themeName ? themeName : defaultTheme;

    const i18n = createI18n({
      locale: currentTheme,
      ...(currentTheme !== defaultTheme && { fallbackLocale: defaultTheme }),
      messages: {
        [defaultTheme]: defaultContent,
      },
    });

    setTheme(i18n, currentTheme);

    return i18n;
  };

  const setTheme = (i18n: I18n, theme: string): void => {
    if (i18n.mode === "legacy") i18n.global.locale = theme;
    else (i18n.global.locale as Ref).value = theme;

    document.documentElement.setAttribute("theme", theme);
    store.commit("app/SET_THEME", theme);
  };

  const loadTheme = async (i18n: I18n, locale: string) => {
    const themeName = getThemeName(locale);

    if (themeName) {
      const content = await import(
        /* webpackChunkName: "locale-[request]" */ `@/locales/${themeName}.json`
      );
      i18n.global.setLocaleMessage(themeName, content.default);
      setTheme(i18n, themeName);
    } else {
      console.warn("Provided theme is not supported.");
    }

    return nextTick();
  };

  const getThemeName = (locale: string | null): string | undefined => {
    return subdomainToTheme[locale as Subdomain];
  };

  return {
    setup,
    setTheme,
    loadTheme,
  };
}
