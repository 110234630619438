export enum MUTATIONS {
  SET_INPUT = "SET_INPUT",
  SET_STORY = "SET_STORY",
  SET_AUDIO = "SET_AUDIO",
  SET_OPTION = "SET_OPTION",
  SET_KEYWORD_ONE = "SET_KEYWORD_ONE",
  SET_KEYWORD_TWO = "SET_KEYWORD_TWO",
  SET_INPUT_AUDIO = "SET_INPUT_AUDIO",
  SET_OUTPUT_AUDIO = "SET_OUTPUT_AUDIO",
  SET_RECORDER = "SET_RECORDER",
  SET_STATUS = "SET_STATUS",
  SET_TOPICS = "SET_TOPICS",
  SET_KEYWORDS = "SET_KEYWORDS",
  SET_GREETING = "SET_GREETING",
  SET_VIDEO = "SET_VIDEO",
  RESET = "RESET",
  SET_CURRENT_ACTOR = "SET_CURRENT_ACTOR",
  SET_ACTORS = "SET_ACTORS",
  SET_VOICES = "SET_VOICES",
  SET_NLP_TOKENS = "SET_NLP_TOKENS",
  RESET_AUDIO_FILES = "RESET_AUDIO_FILES",
  SET_THEME = "SET_THEME",
  SET_ACTIVE_ACTOR = "SET_ACTIVE_ACTOR",
  SET_ACTIVE_VOICE = "SET_ACTIVE_VOICE",
  SET_CONFIRMATION = "SET_CONFIRMATION",
  ADD_RECORDING = "ADD_RECORDING",
}
