// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export interface HotjarOptions {
  siteId: number;
}

export default {
  install: (app: unknown, options: HotjarOptions) => {
    if (!Number.isInteger(options.siteId)) {
      return;
    }

    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function (...args) {
          (h.hj.q = h.hj.q || []).push(args);
        };
      h._hjSettings = { hjid: options.siteId, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  },
};
