export interface Status<T> {
  type: T;
  msg?: string;
}

export enum FormStatus {
  Idle,
  Loading,
  Error,
  Success,
}

export enum CreateState {
  Idle = "idle",
  Error = "error",
  Success = "success",
  Loading = "loading",
}

export enum RecordingState {
  Idle = "idle",
  Recording = "recording",
  Stopped = "stopped",
  Cancelled = "cancelled",
  Error = "error",
}

export enum AudioPreviewState {
  Idle,
  Loading,
  Loaded,
  Playing,
  Paused,
  Ended,
}
