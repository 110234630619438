import dayjs from "dayjs";

export const GENERIC_ERROR = "An error has occurred. Please, try again later.";

interface LimitContext {
  triesLeft: number | null;
  resetDate: Date | null;
  limit: number | false;
}

export const limitMessage = (context: LimitContext) => {
  const { triesLeft, resetDate, limit } = context;

  if (limit === false) {
    return "No limits";
  }

  if (triesLeft == null) {
    return "";
  }

  if (triesLeft <= 0) {
    const textDiff = dayjs(resetDate).from(dayjs().utc());

    return `Limit resets ${textDiff}`;
  }
  return `${triesLeft} of ${limit} turns`;
};
