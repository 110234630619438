
import { computed, defineComponent } from "vue";
import AppIcon from "@/components/utils/AppIcon.vue";
import AppButton from "@/components/utils/AppButton.vue";
import LogoutIcon from "@/components/icons/IconLogout.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    LogoutIcon,
    AppIcon,
    AppButton,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const isAuthenticated = computed(
      () => store.getters["user/isAuthenticated"]
    );

    const handleLogout = () => {
      router.push({ name: "home" });
    };

    return {
      isAuthenticated,
      handleLogout,
    };
  },
});
